<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title> Cambiar contraseña </v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon @click="$emit('close')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
    </v-toolbar>

    <v-form ref="form" class="pa-3">
      <v-text-field
        dense
        hide-details="auto"
				type="password"
        label="Nueva contraseña"
        filled
        :rules="[rules.req]"
				v-model="password"
      >
      </v-text-field>

      <v-text-field
        dense
        hide-details="auto"
        label="Repetir contraseña"
				type="password"
        filled
        :rules="[rules.req, (v) => v == password || 'Las contraseñas no coinciden']"
        class="mt-4"
      >
      </v-text-field>

      <v-btn
        class="mt-4"
        rounded
        color="primary"
        block
        @click.prevent="cambiarPassword"
				large
      >
        Cambiar contraseña
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";

export default {
	props: {
		idUsuario: Number
	},
  data() {
    return {
      rules: { req },
      password: null,
    };
  },
  methods: {
    cambiarPassword() {
      if (!this.$refs.form.validate()) return;

			axios({
				url: '/usuarios/cambiarPassword',
				method: "PUT",
				data: {
					idUsuario: this.idUsuario,
					password: this.password
				}
			}).then(res => {
				this.$root.$emit('snack', 'Contraseña cambiada con éxito')
				this.$emit('close')
			}).catch(err => {
				console.err(err)
				this.$root.$emit('snack', 'No se ha podido cambiar la contraseña')
			})

    },
  },
};
</script>

<style>
</style>